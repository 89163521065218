import { createAPIendpoint } from '../api/api';
import React,{useState,useEffect} from 'react';
import { IMG_URL } from "../api/parameters";
import { Link } from 'react-router-dom';
export function Header(){
    return(
        <header className="main-header header-style">
        <div className="header-top">
            <div className="auto-container">
                <div className="inner-container clearfix">
                <div className="top-left">
                            <ul className="contact-list clearfix">
                                <li><a target="_blank" href="https://api.whatsapp.com/send?phone=5334066958">
    <i className="la la-whatsapp"></i> Justyna
    +90 533 406 69 58
</a></li>
                                <li>
                                    <i className="la la-home"></i>
                                    18. Sk No:1, Obagol
                                    Alanya/TURKEY
                                </li>
                                <li><a href="mailto:info@domwturcji.pl"><i className="la la-envelope-o"></i> info@domwturcji.pl</a></li>
                            </ul>
                        </div>
                        <div className="top-right">
                            <ul>
                            </ul>
                            <ul className="social-icon-one clearfix">
                                <li><a href="https://www.facebook.com/groups/561049827946215/"><i className="la la-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/domwturcji/"><i className="la la-instagram"></i></a></li>                                
                                <li><a href="#"><i className="la la-youtube"></i></a></li>
                            </ul>
                        </div>
                </div>
            </div>
        </div>
        <div className="header-lower">
            <div className="auto-container">
                <div className="main-box">
                    <div className="inner-container clearfix">
                        <div className="logo-box">
                            <div className="logo"><Link to="/"><img src="assets/images/logo.png" alt="" title=""/></Link></div>
                        </div>

                        <div className="nav-outer clearfix">
                            <nav className="main-menu navbar-expand-md navbar-light">
                                <div className="navbar-header">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="icon flaticon-menu"></span>
                                    </button>
                                </div>

                                <div className="collapse navbar-collapse clearfix" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li><Link to="/">Strona główna</Link></li>
                                            <li><Link to="/nieruchomosci-wykaz">Nieruchomości</Link></li>
                                            <li><Link to="/przewodnik">Przewodnik</Link></li>
                                            <li><Link to="/Blog">Blog</Link></li>
                                            <li><a target="_blank" href="https://metintour.pl/">Wycieczki w Turcji</a></li>
                                            <li className="dropdown"><a href="#">Usługi</a>
                                            <ul>
                                                <li><Link to="/Transfery">Transfery</Link></li>
                                                <li><Link to="/Ubezpieczenia">Ubezpieczenia</Link></li>
                                                <li><Link to="/Zarzadzanie-Nieruhomoscia">Zarządzanie Nieruchomością</Link></li>
                                                <li><Link to="/Wycena-i-Sprzedaz-Nieruchomosci">Wycena i Sprzedaż Nieruchomości</Link></li>
                                            </ul>
                                        </li>
                                            <li><Link to="/kontakt">Kontakt</Link></li>
                                        </ul>
                                    </div>
                            </nav>
                     </div>
                 </div>
             </div>
         </div>
     </div>
     <div className="sticky-header">
        <div className="auto-container clearfix">
            <div className="logo pull-left">
                <Link to="/" title=""><img src="assets/images/logo.png" alt="Dom w Turcji" title="Dom w Turcji"/></Link>
            </div>
            <div className="pull-right">
            <nav className="main-menu">
                            <div className="navbar-collapse show collapse clearfix">
                                <ul className="navigation clearfix">
                                    <li><Link to="/">Strona główna</Link></li>
                                    <li><Link to="/nieruchomosci-wykaz">Nieruchomości</Link></li>
                                    <li><Link to="/przewodnik">Przewodnik</Link></li>
                                    <li><Link to="/Blog">Blog</Link></li>
                                    <li><a target="_blank" href="https://metintour.pl/">Wycieczki w Turcji</a></li>
                                    <li className="dropdown"><a href="#">Usługi</a>
                                            <ul>
                                                <li><Link to="/Transfery">Transfery</Link></li>
                                                <li><Link to="/Ubezpieczenia">Ubezpieczenia</Link></li>
                                                <li><Link to="/Zarzadzanie-Nieruhomoscia">Zarządzanie Nieruchomością</Link></li>
                                                <li><Link to="/Wycena-i-Sprzedaz-Nieruchomosci">Wycena i Sprzedaż Nieruchomości</Link></li>
                                            </ul>
                                        </li>
                                    <li><Link to="/kontakt">Kontakt</Link></li>
                                </ul>
                            </div>
                        </nav>
    </div>
</div>
</div>
</header>
    )
}
export function Footer(footerImg){
    const [data, setData] = useState({});
    const footerImage = {
        backgroundImage: footerImg.footerImg,
    }; 
    useEffect(() => {    
        createAPIendpoint("Getfooterblog")
        .Getfooterblogs()
        .then(res=>setData(res.data))
        .catch(err=>console.log(err))
  }, []); 
    return(
        <footer className="main-footer" style={footerImage}>
 <div className="auto-container">
                <div className="upper-box">
                    <div className="row">
                        <div className="upper-column col-lg-3 col-md-12 col-sm-12">
                            <div className="footer-logo">
                                <figure className="image"><Link to="/"><img src="assets/images/logowhite.png" alt="Dom w Turcji"/></Link></figure>
                            </div>
                        </div>

                        <div className="upper-column col-lg-6 col-md-12 col-sm-12">
                            <div className="subscribe-form">
                                <form method="post" action="#">
                                    <div className="form-group">
                                        <input type="email" name="email" placeholder="Email" required=""/>
                                        <button type="submit" className="theme-btn btn-style-four">Wyślij</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="upper-column col-lg-3 col-md-12 col-sm-12">
                            <div className="social-links">
                                <ul className="social-icon-two">
                                    <li><a href="https://www.facebook.com/groups/561049827946215/"><i className="la la-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/domwturcji/"><i className="la la-instagram"></i></a></li>
                                    <li><a href="#"><i className="la la-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="widgets-section">
                    <div className="row">
                        <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="row">
                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget contact-widget">
                                        <h2 className="widget-title">Kontakt</h2>
                                        <div className="widget-content">
                                            <ul className="contact-list">
                                                <li><span className="la la-map-marker"></span> 18. Sk No:1, Obagol <br /> Alanya/TURKEY</li>
                                                <li><span className="la la-phone"></span>+90 242 514 1479</li>
                                                <li><span className="la la-envelope"></span><a href="mailto: info@domwturcji.pl"> info@domwturcji.pl</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-column col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget links-widget">
                                        <h2 className="widget-title"></h2>
                                        <div className="widget-content">
                                            <ul className="list clearfix">
                                                <li><Link to="/nieruchomosci-wykaz">Nieruchomości</Link></li>
                                                <li><Link to="/przewodnik">Przewodnik</Link></li>
                                                <li><Link to="/Blog">Blog</Link></li>
                                                <li><a target="_blank" href="https://metintour.pl/">Wycieczki w Turcji</a></li>
                                                <li><Link to="/O-nas">O nas</Link></li>
                                    <li><Link to="/kontakt">Kontakt</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                <div className="footer-column col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget popular-posts">
                                        <h2 className="widget-title">Najnowsze Posty</h2>
                                        <div className="widget-content">
                                        {data.blogs?.map((item)=>(
                                            <div key={item.id} className="post">
                                                <div className="thumb"><Link to={'/blog/'+item.href}><img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/></Link></div>
                                                <h4><Link to={'/blog/'+item.href}>{item.name}</Link></h4>
                                                <span className="date">{item.date}</span>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget popular-posts">
                                        <h2 className="widget-title">Przewodnik</h2>
                                        <div className="widget-content">
                                        {data.guides?.map((item)=>(
                                            <div key={item.id} className="post">
                                                <div className="thumb"><Link to={'/przewodnik/'+item.href}><img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/></Link></div>
                                                <h4><Link to={'/przewodnik/'+item.href}>{item.name}</Link></h4>
                                                <span className="date">{item.date}</span>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="auto-container">


                    <div className="inner-container clearfix">
                        <div className="copyright-text">
                            <p>
                                Copyright ©
                                <script>
                                    var d = new Date();
                                    document.write(d.getFullYear());
                                </script> Dom w Turcji.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
</footer>
    )
}