
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import { Link, useLoaderData, useParams } from "react-router-dom";
import React,{useState,useEffect} from 'react';
import { Footer, Header } from './shared';
import { createAPIendpoint } from '../api/api';
import { IMG_URL } from "../api/parameters"
const useScript = (src,condition) => {
    useEffect(() => {
            const tag = document.createElement('script');
            tag.async = condition;
            tag.src = src;
            document.body.appendChild(tag);            
            return () => {
              document.body.removeChild(tag);
            }      
    }, [src])  
  }
export const PDetail=()=>{
    const [formdata,setFormdata]=useState({
        Name:"",
        Email:"",
        Phone:"",
        Message:""
    })
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     const data=useLoaderData();
  function sendContactform(e){
    e.preventDefault();
    document.getElementById("contact-submit").disabled=true; 
    createAPIendpoint("Contactform")
        .sendContactform(formdata)
        .then(
            res=>alert("Twoja wiadomość została wysłana pomyślnie.."))
        .catch(
            err=>alert("Błąd serwera.Proszę o kontakt przez admin@domwturcji.pl...!")
            );
}
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
};
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>OPIS SZCZEGÓŁOWY NIERUCHOMOŚCI</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li><Link to="/nieruchomosci-wykaz">NIERUCHOMOŚCI</Link></li>
                      <li>OPIS SZCZEGÓŁOWY NIERUCHOMOŚCI</li>
                  </ul>
              </div>
          </div>
      </section>
        }
      
<div className="sidebar-page-container">
    <div className="auto-container">
        <div className="upper-info-box">
            <div className="row">
                <div className="about-property col-lg-8 col-md-12 col-sm-12">
                    <h2>{data.property?.title}</h2>
                    <div className="location"><i className="la la-map-marker"></i>{data.property?.address +" "+ data.property?.location}</div>
                    <ul className="property-info clearfix">
                    <li><i className="flaticon-small-calendar"></i>{data.property?.builddate}</li>
                    <li><i className="flaticon-dimension"></i>{data.property?.area}</li>
                    <li><i className="flaticon-house-silhouette"></i>{data.property?.room}</li>
                    <li><i className="flaticon-can-sun-umbrella-3"></i>{data.property?.dSea}</li>
                    <li><i className="flaticon-can-plane-1"></i>{data.property?.dAirport}</li>
                    </ul>
                </div>
                <div className="price-column col-lg-4 col-md-12 col-sm-12">
                    <div className="price">{data.property?.price}</div>
                    <span className="status">{data.property?.type}</span>
                </div>
            </div>
        </div>
        
        <div className="row clearfix">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="property-detail">
                {data?.property &&
        <div className="upper-box">
        <div className="carousel-outer">
            <ul className="image-carousel owl-carousel owl-theme">
            {data.property?.gallery.split('æ').map((item,i)=>(
                <li key={i}><img src={IMG_URL+'mc/770/470/5/gallery/'+data.property?.id+'/'+item} alt={data.property?.title+'-'+i}/></li>
            ))} 
               
            </ul>
            
            <ul className="thumbs-carousel owl-carousel owl-theme">
            {data.property?.gallery.split('æ').map((item,i)=>(
                <li key={i}><img src={IMG_URL+'mc/170/110/5/gallery/'+data.property?.id+'/'+item} alt={data.property?.title+'-'+i}/></li>
            ))} 
            </ul>
        </div>
    </div>
    }
                    

                    <div className="lower-content">
                        <h4>SKRÓCONY OPIS</h4>
                        <p>{data.property?.shortdesc}</p>
                    </div>
                    <div className="lower-content">
                        <h4>OPIS</h4>
                        {parse(data.property?.longdesc||'')}
                    </div>

                    <div className="property-features">
                        <h4>INFORMACJE SZCZEGÓŁOWE</h4>
                        <ul className="list-style-one">
                            <li>{data.property?.price}</li>
                            <li>{data.property?.type}</li>
                            <li>{data.property?.category}</li>
                            <li>{data.property?.area}</li>
                            <li>{data.property?.location}</li>
                            <li>{data.property?.room}</li>
                        </ul>
                    </div>
                    <div className="property-features">
                        <h4>UDOGODNIENIA</h4>
                        <ul className="list-style-one">
                        {data.property?.amenities.split(',').map((item,i)=>(
                            <li key={i}>{item}</li>
                        ))}                           
                        </ul>
                    </div>
                    {data.property?.floorplan &&
                    <div className="flooring-tabs tabs-box">
                    <div className="clearfix">
                        <h4>PLANY</h4>
                        <ul className="tab-buttons">
                        {data.property?.floorplan.split('æ').map((item,i)=>(
                            <li key={i} data-tab={"#"+item.split('₺')[0].replaceAll(" ","-")} className={i===0 ? "tab-btn active-btn" : "tab-btn"}>{item.split('₺')[0]}</li>
                        ))}       
                        </ul>                
                    </div>

                    <div className="tabs-content">
                    {data.property?.floorplan.split('æ').map((item,i)=>(
                        <div key={i} className={i===0 ? "tab active-tab" : "tab"} id={item.split('₺')[0].replaceAll(" ","-")}>
                        <div className="floor-image">{ item.split('₺')[1].split('.')[1].toLowerCase()==='pdf' ?                        
                        <figure className="image">
                            <iframe
    src={"https://drive.google.com/viewerng/viewer?embedded=true&url="+IMG_URL+'5/floorplan/'+data.property?.id+'/'+item.split('₺')[1]+"#toolbar=0&scrollbar=0"}
    height="1000px"
    width="100%"
></iframe>
</figure>
                        :
 <a target='_blank' href={IMG_URL+'m/5000/5000/5/floorplan/'+data.property?.id+'/'+item.split('₺')[1]}> <figure className="image"><img src={IMG_URL+'m/5000/5000/5/floorplan/'+data.property?.id+'/'+item.split('₺')[1]} alt={data.property?.title+'-'+i}/></figure></a>

}                                    
                                       
                        </div>
                    </div>
                        ))}                      
                        
                    </div>
                </div>
                    }
                    
            </div>
        </div>
        <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
            <aside className="sidebar default-sidebar">
                <div className="sidebar-widget agent-widget">
                    <div className="sidebar-title"><h2>INFORMACJE O AGENCIE</h2></div>
                    <div className="widget-content">
                    {data.agents  &&
                    <div className="image-box">
                    <figure className="image"><img src={IMG_URL+'mc/600/770/5/agent/'+data.agents?.photo} alt={data.property?.agent}/></figure>
                </div>
                    }
                        
                        <div className="info-box">
                            <h4 className="name">{data.property?.agent}</h4>
                            <span className="designation">{data.agents?.duty}</span>
                            <ul className="contact-info">
                                <li><strong>TELEFON:</strong>{data.agents?.tel}</li>
                                <li><strong>EMAIL:</strong>{data.agents?.email}</li>
                            </ul>
                            <div className="btn-box">
                                <Link to={'/przedstawiciel/'+data.agents?.id} className="theme-btn btn-style-one">ZOBACZ PROFIL AGENTA</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidebar-widget agent-from-widget">
                    <div className="sidebar-title"><h2>SKONTAKUJ SIĘ Z NAMI</h2></div>
                    <div className="widget-content">
                        <div className="agent-form">
                            <form method="post" action="#" id="contact-form" onSubmit={(e)=>sendContactform(e)}>
                                <div className="form-group">
                                    <input type="text" name="username" placeholder="IMIĘ" 
                                    onChange={(e)=>setFormdata({...formdata,Name:e.target.value})}
                                    required/>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" placeholder="EMAIL" 
                                    onChange={(e)=>setFormdata({...formdata,Email:e.target.value})}
                                    required/>
                                </div>
                                <div className="form-group">
                                    <input type="number" name="number" placeholder="NUMER TELEFONU" 
                                    onChange={(e)=>setFormdata({...formdata,Phone:e.target.value})}
                                    required/>
                                </div>
                                <div className="form-group">
                                    <textarea name="message" placeholder="WIADOMOŚĆ"
                                    onChange={(e)=>setFormdata({...formdata,Message:"Property : "+" "+data.property?.title+" "+ e.target.value})}
                                    ></textarea>
                                </div>
                                <div className="form-group">
                                    <button id="contact-submit" className="theme-btn btn-style-one" type="submit" name="submit-form">WYŚLIJ WIADOMOŚĆ</button>
                                </div>                                
                            </form>
                        </div>
                    </div>
                    <div className="form-group text-center">
                                <a href="https://wa.me/+905334066958/?text=Witam" target="_blank" className="theme-btn btn-style-one bg-success">KONTAKT PRZEZ WHATSAPP</a>
                            </div>
                </div>
                <div className="sidebar-widget categories">
                    <div className="sidebar-title"><h2>RODZAJE NIERUCHOMOŚCI</h2></div>
                    <ul className="cat-list">
                    {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'/nieruchomosci-wykaz/kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}                     
                    </ul>
                </div>
                
            </aside>
        </div>
    </div>
</div>
</div>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
       
        </>
       
        
    )
}
export const propertyDetailLoader = async({params})=>{
    const {id}=params;
    const response= await createAPIendpoint("Getproperty")
    .getPropertydetail(id);
    return response.data;
  }