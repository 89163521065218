import React,{useEffect} from 'react';
import { createAPIendpoint } from '../api/api';
import { Footer, Header } from './shared';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IMG_URL } from "../api/parameters"
import parse from 'html-react-parser';
import { Link,useLoaderData } from 'react-router-dom';
const useScript = (src,condition) => {
    useEffect(() => {
            const tag = document.createElement('script');
            tag.async = condition;
            tag.src = src;
            document.body.appendChild(tag);            
            return () => {
              document.body.removeChild(tag);
            }      
    }, [src])  
  }
export const Transfer=()=>{

    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     const data =useLoaderData();
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data?.slider+')'

}; 
    return(
        <>
           <HelmetProvider>
            <Helmet>
            <title>{data?.title}</title>
        <meta name="description" content={data?.seod}/>
        <meta name="keywords" content={data?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.slider  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>TRANSFERY</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li>TRANSFERY</li>
                  </ul>
              </div>
          </div>
      </section>
        }
       <section className="testimonial-page-section">
 <div className="auto-container">
  {parse(data?.body||'')}

</div>
</section>
        {data.footer &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data?.footer+')'}/>
}
        </div>
        </>
    )
}
export const transferLoader = async()=>{
    const response= await createAPIendpoint("GetpageById")
    .GetpageById(8)
    return response.data;
}