import { Link, useLoaderData, useParams } from "react-router-dom";
import React,{useEffect} from 'react';
import { Footer, Header } from './shared';
import { createAPIendpoint } from '../api/api';
import { IMG_URL } from "../api/parameters"
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
const useScript = (src,condition) => {
    useEffect(() => {
            const tag = document.createElement('script');
            tag.async = condition;
            tag.src = src;
            document.body.appendChild(tag);            
            return () => {
              document.body.removeChild(tag);
            }      
    }, [src])  
  }
export const Agent=()=>{
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     const data=useLoaderData();
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>Szczegóły Agenta</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">Home</Link></li>
                      <li>Szczegóły Agenta</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <section class="agent-detail">
    <div class="auto-container">
        <div class="row">
            <div class="image-column col-lg-4 col-md-12 col-sm-12">
                <div class="inner-column">
                    <div class="image-box">
                        <figure class="image"><img src={IMG_URL+'mc/600/770/5/agent/'+data.agents?.photo} alt={data.property?.agent}/></figure>
                    </div>
                </div>
            </div>

            <div class="content-column col-lg-8 col-md-12 col-sm-12">
                <div class="inner-column">
                    <div class="about-agent">
                        <h3 class="name">{data.agents?.name}</h3>
                        <span class="designation">{data.agents?.duty}</span>
                        <div class="text">{parse(data.agents?.explain||'')} </div>
                        <div class="contact-info">
                            <div class="row">
                                <div class="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <ul>
                                        <li><i class="la la-phone"></i>{data.agents?.tel}</li>
                                        <li><i class="la la-envelope-o"></i> <a href={'mailto:'+data.agents?.email}>{data.agents?.email}</a></li>
                                    </ul>
                                </div>

                                <div class="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <ul>
                                        <li><i class="la la-home"></i>{data.agents?.adress}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="property-section alternate">
    <div class="auto-container">
        <div class="sec-title">
            <span class="title">ZNAJDŹ SWÓJ WYMARZONY DOM</span>
            <h2>NAJCIEKAWSZE OFERY</h2>
        </div>

        <div class="row">
        {data.properties?.map((item)=>(
 <div key={item.id} className={"property-block all mix "+ item.type.split(' ')[1]+" col-lg-4 col-md-6 col-sm-12"}>
 <div className="inner-box">
     <div className="image-box">
     <Link to={'/nieruchomosci/'+item.id}><figure className="image"><img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cPicture} alt={item.title}/></figure></Link>
         <span className="for">{item.type}</span>
         <span className="featured">{item.price}</span>
         <ul className="info clearfix">
             <li><i className="la la-calendar-minus-o"></i>{item.builddate}</li>
         </ul>
     </div>
     <div className="lower-content">
         <ul className="tags">
             <li><Link to={'/nieruchomosci-wykaz/kategoria/'+item.category}>{item.category}</Link></li>
         </ul>
         <div className="thumb"><img src={IMG_URL+'mc/60/60/5/agent/'+data.agents?.avatar} alt={data.agents?.name}/></div>
         <h3><Link to={'/nieruchomosci/'+item.id}>{item.title}</Link></h3>
         <div className="lucation"><i className="la la-map-marker"></i>{item.address +" "+ item.location}</div>
         <ul className="property-info clearfix">
                                            <li className="col-md-6"><i className="flaticon-small-calendar"></i>{item.builddate}</li>
                                            <li className="col-md-6"><i className="flaticon-dimension"></i>{item.area}</li>
                                            <li className="col-md-6"><i className="flaticon-house-silhouette"></i>{item.room}</li>
                                            <li className="col-md-6"><i className="flaticon-maps-and-flags"></i>{item.location}</li>
                                            <li className="col-md-6"><i className="flaticon-can-sun-umbrella-3"></i>{item.dSea}</li>
                                            <li className="col-md-6"><i className="flaticon-can-plane-1"></i>{item.dAirport}</li>
                                        </ul>
     </div>
 </div>
</div>
    ))}  
    </div>
</div>
</section>
<section class="clients-section style-three">
    <div class="auto-container">
        <div class="sponsors-outer">
        <ul className="sponsors-carousel owl-carousel owl-theme">
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://metintour.pl"><img src="/assets/images/clients/metintour.png" alt="Metin Tour"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://nonstoptransfer.com"><img src="/assets/images/clients/nonstoptransfer.png" alt="Non Stop Transfer"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://turkusturizm.com.tr"><img src="/assets/images/clients/Turkustourism.jpg" alt="Turkus Tourism"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://domwturcji.pl"><img src="/assets/images/clients/Domwturcjiorj.png" alt="Dom w Turcji"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://alanyamotor.com"><img src="/assets/images/clients/metam-logo.png" alt="Honda Alanya Motor"/></a></figure></li>
            </ul>
        </div>
    </div>
</section>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
        </>
    )
}
export const agentDetailLoader = async({params})=>{
    const {id}=params;
    const response= await  createAPIendpoint("Getagent")
    .Getagent(id)
    return response.data;
  }