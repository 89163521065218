import React,{useState,useEffect} from 'react';
import { createAPIendpoint } from '../api/api';
import { Footer, Header } from './shared';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IMG_URL } from "../api/parameters"
import { Link,useLoaderData } from 'react-router-dom';

const useScript = (src,condition) => {
    useEffect(() => {
            const tag = document.createElement('script');
            tag.async = condition;
            tag.src = src;
            document.body.appendChild(tag);            
            return () => {
              document.body.removeChild(tag);
            }      
    }, [src])  
  }
export const Contact=()=>{
    const data =useLoaderData();
    const [formdata,setFormdata]=useState({
        Name:"",
        Email:"",
        Phone:"",
        Message:""
    })
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);  
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data?.slider+')'

}; 
function sendContactform(e){
    e.preventDefault();
    document.getElementById("contact-submit").disabled=true;      
    createAPIendpoint("Contactform")
        .sendContactform(formdata)
        .then(
            res=>alert("Twoja wiadomość została wysłana pomyślnie.."))
        .catch(
            err=>alert("Błąd serwera.Proszę o kontakt przez admin@domwturcji.pl...!")
            );
}
    return(
        <>
           <HelmetProvider>
            <Helmet>
            <title>{data?.title}</title>
        <meta name="description" content={data?.seod}/>
        <meta name="keywords" content={data?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.slider  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>Kontakt</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li>Kontakt</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <section className="contact-section style-two">
    <div className="auto-container">
        <div className="row">
            <div className="form-column col-lg-8 col-md-6 col-sm-12">
                <div className="inner-column">
                    <div className="title-box">
                        <h2>SKONTAKTUJ SIĘ Z NAMI W JĘZYKU POLSKIM</h2>
                        <div className="text">ZADAJ NAM PYTANIE</div>
                    </div>

                    <div className="contact-form">
                        <form method="post" action="#" id="contact-form" onSubmit={(e)=>sendContactform(e)}>
                            <div className="form-group">
                                <input type="text" name="username" placeholder="Imię" 
                                onChange={(e)=>setFormdata({...formdata,Name:e.target.value})}
                                required/>
                            </div>
                            
                            <div className="form-group">
                                <input type="email" name="email" placeholder="E-mail" 
                                onChange={(e)=>setFormdata({...formdata,Email:e.target.value})}
                                required/>
                            </div>

                            <div className="form-group">
                                <input type="text" name="tel" placeholder="Telefon" 
                                onChange={(e)=>setFormdata({...formdata,Phone:e.target.value})}
                                required/>
                            </div>

                            <div className="form-group">
                                <textarea name="message" placeholder="Wiadomość" 
                                onChange={(e)=>setFormdata({...formdata,Message:e.target.value})}
                                ></textarea>
                            </div>
                            
                            <div className="form-group">
                                <button id="contact-submit" className="theme-btn btn-style-one" type="submit" name="submit-form">WYŚLIJ 
</button>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>

            <div className="info-column col-lg-4 col-md-6 col-sm-12">
                <div className="inner-column">

                  <div className="title-box">                          
                </div>
                <div className="contact-info">
                 <div className="contact-info-box">
                    <div className="inner-box">
                        <span className="icon la la-phone"></span>
                        <ul>
                            <li>+90 242 514 1479</li>
                            <li>+90 533 406 69 58</li>
                        </ul>
                    </div>
                </div>

                <div className="contact-info-box">
                    <div className="inner-box">
                        <span className="icon la la-envelope-o"></span>
                        <ul>
                            <li><a href="mailto:info@domwturcji.pl">info@domwturcji.pl</a></li>
                        </ul>
                    </div>
                </div>

                <div className="contact-info-box">
                    <div className="inner-box">
                        <span className="icon la la-globe"></span>
                        <ul>
                            <li>Metin Tour – Polskie Biuro
Podróży W Turcji, 18. Sk No:1,
Obagol, 07400
Alanya/Antalya</li>
                        </ul> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
</div>
</section>

<section className="map-section">
    <div className="text-center">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51302.33737655173!2d32.025370804926446!3d36.52043756138568!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14dc9852deac6eb5%3A0xf45fdc5431a5c106!2sMetin%20Tour%20Alanya%20-%20Polskie%20Biuro%20Podr%C3%B3%C5%BCy%20w%20Turcji%20WYCIECZKI%20FAKULTATYWNE!5e0!3m2!1str!2str!4v1665687698939!5m2!1str!2str" width="800" height="600" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
</div>
</section>
        {data.footer &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data?.footer+')'}/>
}
        </div>
        </>
    )
}
export const contactLoader = async()=>{
    const response= await createAPIendpoint("GetpageById")
    .GetpageById(6)
    return response.data;
}