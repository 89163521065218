import axios from "axios";
import { BASE_URL } from "./parameters";
export const createAPIendpoint=endpoint=>{
    let url=BASE_URL+'api/'+endpoint+'/';
    return{
        getMainpage:id=>axios.get(url+id),
        getPropertydetail:id=>axios.get(url+id),
        getPropertylist:()=>axios.get(url),
        getPropertylistbycat:cat=>axios.get(url+cat),
        getPropertylistbycity:city=>axios.get(url+city),
        Getagent:id=>axios.get(url+id),
        Getcities:()=>axios.get(url),
        Getcity:id=>axios.get(url+id),
        Getblog:href=>axios.get(url+href),
        Getfooterblogs:()=>axios.get(url),
        Getbloglist:()=>axios.get(url),
        Getguidelist:()=>axios.get(url),
        Getbloglistbycat:cat=>axios.get(url+cat),
        GetpageById:id=>axios.get(url+id),
        Getguide:href=>axios.get(url+href),
        getPropertylistbysearch:data=>axios.post(url,data,{ headers: { 'Accept': 'application/json',
     'Content-Type': 'application/json' } }),
        sendContactform:data=>axios.post(url,data,{ headers: { 'Accept': 'application/json',
     'Content-Type': 'application/json' } })
    }
}