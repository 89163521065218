import { createAPIendpoint } from '../api/api';
import React,{useState,useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Footer, Header} from './shared';
import { IMG_URL } from "../api/parameters"
import { useParams,useNavigate, Link,useLoaderData, useActionData,Form } from "react-router-dom";
const useScript = (src,condition) => {
    useEffect(() => {
            const tag = document.createElement('script');
            tag.async = condition;
            tag.src = src;
            document.body.appendChild(tag);            
            return () => {
              document.body.removeChild(tag);
            }      
    }, [src])  
  }  
export const PList=()=>{   
    const data =useLoaderData();
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>NIERUCHOMOŚCI</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li>NIERUCHOMOŚCI</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <div className="sidebar-page-container">
    <div className="auto-container">
        <div className="row clearfix">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="property-list-view">
                    <div className="upper-box clearfix">
                        <div className="sec-title">
                            <span className="title">ZNAJDŹ SWÓJ WYMARZONY DOM</span>
                            <h2>NIERUCHOMOŚCI</h2>
                        </div>
                    </div>
                    {data.properties?.map((item,i)=>(
                        <div key={item.id} className="property-block-three">
                        <div className="inner-box">
                            <div className="row clearfix">
                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="image-box">
                                    <Link to={'/nieruchomosci/'+item.id}><figure className="image"><img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cPicture} alt={item.title}/></figure></Link>                                        
                                    <span className="for">{item.price}</span>
                                    <span className="featured">{item.type}</span>
                                        <ul className="info clearfix">
                                            <li><i className="la la-calendar-minus-o"></i>{item.builddate}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="lower-content">
                                        <ul className="tags">
                                            <li><Link to={'/nieruchomosci-wykaz/kategoria/'+item.category}>{item.category}</Link></li>
                                        </ul>
                                        <div className="thumb"><img src={IMG_URL+'mc/60/60/5/agent/'+item.agentp} alt={item.title}/></div>
                                        <h3><Link to={'/nieruchomosci/'+item.id}>{item.title}</Link></h3>
                                        <div className="lucation"><i className="la la-map-marker"></i>{item.address +" "+ item.location}</div>
                                        <ul className="property-info clearfix">
                                        <li className="col-md-6"><i className="flaticon-dimension"></i>{item.area}</li>
                                            <li className="col-md-6"><i className="flaticon-house-silhouette"></i>{item.room}</li>
                                            <li className="col-md-6"><i className="flaticon-can-sun-umbrella-3"></i>{item.dSea}</li>
                                            <li className="col-md-6"><i className="flaticon-can-plane-1"></i>{item.dAirport}</li>
                                        </ul>
                                        <div className="property-price clearfix">
                                            <div className="read-more"><Link to={'/nieruchomosci/'+item.id} className="theme-btn">SZCZEGÓŁY</Link></div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        ))}   
                    
                   
                </div>
            </div>
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar default-sidebar">
                <div className="sidebar-widget search-properties">
                    <div className="sidebar-title"><h2>ZNAJDŹ NIERUCHOMOŚĆ</h2></div>
                    <div className="property-search-form style-three">
                    <Form method='post' action='/nieruchomosci-wykaz/Szukaj'>
                            <div className="row no-gutters">
                            <div className="form-group">
                                    <input type="text" name="text" placeholder='NAZWA' />
                                </div>
                                <div className="form-group">
                                    <select id="tip" name="tip" className="custom-select-box">
                                    <option value="Any">KUPNO/WYNAJEM</option>
                                    <option value="Na Sprzedaż">Na Sprzedaż</option>
                                        <option value="Do Wynajęcia">Do Wynajęcia</option>
                                        <option value="Sprzedany">Sprzedany</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                <select id="location" name="location" className="custom-select-box">
                                    <option value={"Any"}>LOKALIZACJA</option>
                                    {data.locations?.map((item,i)=>(
                                        <option key={i} value={item}>{item}</option>
                                        ))} 
                                </select>
                                </div>
                                <div className="form-group">
                                <select id="room" name="room" className="custom-select-box">
                                        <option value="Any">ILOŚĆ POKOI</option>
                                        {data.rooms?.map((item,i)=>(
                                            <option key={i} value={item}>{item}</option>
                                            ))} 
                                    </select>
                                </div>
                                <div className="form-group">
                                <div className="range-slider-one clearfix">
                                        <div className="area-range-slider"></div>
                                        <div className="input"><input type="text" className="property-amount" id="area" name="area" readOnly/></div>
                                        <div className="title">m<sup>2</sup></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="range-slider-one clearfix">
                                        <div className="price-range-slider"></div>
                                        <div className="input"><input type="text" className="price-amount" id="price" name="price" readOnly/></div>
                                        <div className="title">Euro</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="theme-btn btn-style-one">WYSZUKAJ</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                    <div className="sidebar-widget categories">
                    <div className="sidebar-title"><h2>RODZAJE NIERUCHOMOŚCI</h2></div>
                    <ul className="cat-list">
                    {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}                     
                    </ul>
                </div>

                </aside>
            </div>
        </div>
    </div>
</div>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
        </>
    )
}
export const PListCat=()=>{   
    const data =useLoaderData();
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
            <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>NIERUCHOMOŚCI</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li>NIERUCHOMOŚCI</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <div className="sidebar-page-container">
    <div className="auto-container">
        <div className="row clearfix">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="property-list-view">
                    <div className="upper-box clearfix">
                    <div className="sec-title">
                            <span className="title">ZNAJDŹ SWÓJ WYMARZONY DOM</span>
                            <h2>NIERUCHOMOŚCI</h2>
                        </div>
                    </div>
                    {data.properties?.map((item,i)=>(
                        <div key={item.id} className="property-block-three">
                        <div className="inner-box">
                            <div className="row clearfix">
                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="image-box">
                                    <Link to={'/nieruchomosci/'+item.id}><figure className="image"><img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cPicture} alt={item.title}/></figure></Link>
                                    <span className="for">{item.price}</span>
                                    <span className="featured">{item.type}</span>
                                        <ul className="info clearfix">
                                            <li><i className="la la-calendar-minus-o"></i>{item.builddate}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="lower-content">
                                        <ul className="tags">
                                            <li><Link to={'/nieruchomosci-wykaz/kategoria/'+item.category}>{item.category}</Link></li>
                                        </ul>
                                        <div className="thumb"><img src={IMG_URL+'mc/60/60/5/agent/'+item.agentp} alt={item.title}/></div>
                                        <h3><Link to={'/nieruchomosci/'+item.id}>{item.title}</Link></h3>
                                        <div className="lucation"><i className="la la-map-marker"></i>{item.address +" "+ item.location}</div>
                                        <ul className="property-info clearfix">
                                        <li className="col-md-6"><i className="flaticon-dimension"></i>{item.area}</li>
                                            <li className="col-md-6"><i className="flaticon-house-silhouette"></i>{item.room}</li>
                                            <li className="col-md-6"><i className="flaticon-can-sun-umbrella-3"></i>{item.dSea}</li>
                                            <li className="col-md-6"><i className="flaticon-can-plane-1"></i>{item.dAirport}</li>
                                        </ul>
                                        <div className="property-price clearfix">
                                            <div className="read-more"><Link to={'/nieruchomosci/'+item.id} className="theme-btn">SZCZEGÓŁY</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        ))}   
                    
                   
                </div>
            </div>
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar default-sidebar">
                <div className="sidebar-widget search-properties">
                    <div className="sidebar-title"><h2>ZNAJDŹ NIERUCHOMOŚĆ</h2></div>
                    <div className="property-search-form style-three">
                    <Form method='post' action='/nieruchomosci-wykaz/Szukaj'>
                            <div className="row no-gutters">
                            <div className="form-group">
                                    <input type="text" name="text" placeholder='NAZWA' />
                                </div>
                                <div className="form-group">
                                    <select id="tip" name="tip" className="custom-select-box">
                                    <option value="Any">KUPNO/WYNAJEM</option>
                                    <option value="Na Sprzedaż">Na Sprzedaż</option>
                                        <option value="Do Wynajęcia">Do Wynajęcia</option>
                                        <option value="Sprzedany">Sprzedany</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                <select id="location" name="location" className="custom-select-box">
                                    <option value={"Any"}>LOKALIZACJA</option>
                                    {data.locations?.map((item,i)=>(
                                        <option key={i} value={item}>{item}</option>
                                        ))} 
                                </select>
                                </div>
                                <div className="form-group">
                                <select id="room" name="room" className="custom-select-box">
                                        <option value="Any">ILOŚĆ POKOI</option>
                                        {data.rooms?.map((item,i)=>(
                                            <option key={i} value={item}>{item}</option>
                                            ))} 
                                    </select>
                                </div>
                                <div className="form-group">
                                <div className="range-slider-one clearfix">
                                        <div className="area-range-slider"></div>
                                        <div className="input"><input type="text" className="property-amount" id="area" name="area" readOnly/></div>
                                        <div className="title">m<sup>2</sup></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="range-slider-one clearfix">
                                        <div className="price-range-slider"></div>
                                        <div className="input"><input type="text" className="price-amount" id="price" name="price" readOnly/></div>
                                        <div className="title">Euro</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="theme-btn btn-style-one">WYSZUKAJ</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                    <div className="sidebar-widget categories">
                    <div className="sidebar-title"><h2>RODZAJE NIERUCHOMOŚCI</h2></div>
                    <ul className="cat-list">
                    {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'/nieruchomosci-wykaz/kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}                     
                    </ul>
                </div>

                </aside>
            </div>
        </div>
    </div>
</div>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
        </>
    )
}
export const PListCity=()=>{   
    const navigate = useNavigate();
    const [data, setData] = useState({});
    let { city } = useParams();
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     //useScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBjirg3UoMD5oUiFuZt3P9sErZD-2Rxc68',true);
     //useScript('./assets/js/map-script.js',true);*/
    useEffect(() => {    
        createAPIendpoint("getPropertylistbycity")
        .getPropertylistbycat(city)
        .then(res=>setData(res.data))
        .catch(err=>console.log(err))
  }, []);
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>Property List</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">Home</Link></li>
                      <li>Property List</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <div className="sidebar-page-container">
    <div className="auto-container">
        <div className="row clearfix">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="property-list-view">
                    <div className="upper-box clearfix">
                    <div className="sec-title">
                            <span className="title">ZNAJDŹ SWÓJ WYMARZONY DOM</span>
                            <h2>NIERUCHOMOŚCI</h2>
                        </div>
                    </div>
                    {data.properties?.map((item,i)=>(
                        <div key={item.id} className="property-block-three">
                        <div className="inner-box">
                            <div className="row clearfix">
                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="image-box">
                                        <figure className="image"><img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cPicture} alt={item.title}/></figure>
                                        <span className="for">{item.price}</span>
                                    <span className="featured">{item.type}</span>
                                        <ul className="info clearfix">
                                            <li><i className="la la-calendar-minus-o"></i>{item.builddate}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="lower-content">
                                        <ul className="tags">
                                            <li><Link to={'/nieruchomosci-wykaz/kategoria/'+item.category}>{item.category}</Link></li>
                                        </ul>
                                        <div className="thumb"><img src={IMG_URL+'mc/60/60/5/agent/'+item.agentp} alt={item.title}/></div>
                                        <h3><Link to={'/nieruchomosci/'+item.id}>{item.title}</Link></h3>
                                        <div className="lucation"><i className="la la-map-marker"></i>{item.address +" "+ item.location}</div>
                                        <ul className="property-info clearfix">
                                        <li className="col-md-6"><i className="flaticon-dimension"></i>{item.area}</li>
                                            <li className="col-md-6"><i className="flaticon-house-silhouette"></i>{item.room}</li>
                                            <li className="col-md-6"><i className="flaticon-can-sun-umbrella-3"></i>{item.dSea}</li>
                                            <li className="col-md-6"><i className="flaticon-can-plane-1"></i>{item.dAirport}</li>
                                        </ul>
                                        <div className="property-price clearfix">
                                            <div className="read-more"><Link to={'/nieruchomosci/'+item.id} className="theme-btn">SZCZEGÓŁY</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        ))}   
                    
                   
                </div>
            </div>
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar default-sidebar">
                <div className="sidebar-widget search-properties">
                    <div className="sidebar-title"><h2>Search Properties</h2></div>
                    <div className="property-search-form style-three">
                    <Form method='post' action='/nieruchomosci-wykaz/Szukaj'>
                            <div className="row no-gutters">
                                <div className="form-group">
                                    <select id="tip" name="tip" className="custom-select-box">
                                    <option value="">Type</option>
                                    <option value="Any">Any</option>
                                    <option value="For Sale">For Sale</option>
                                    <option value="For Rent">For Rent</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                <select id="location" name="location" className="custom-select-box">
                                    <option value="">Location</option>
                                    <option value={"Any"}>Any</option>
                                    {data.locations?.map((item,i)=>(
                                        <option key={i} value={item}>{item}</option>
                                        ))} 
                                </select>
                                </div>
                                <div className="form-group">
                                <select id="room" name="room" className="custom-select-box">
                                      <option value="">Room</option>  
                                        <option value="Any">Any</option>
                                        {data.rooms?.map((item,i)=>(
                                            <option key={i} value={item}>{item}</option>
                                            ))} 
                                    </select>
                                </div>
                                <div className="form-group">
                                <div className="range-slider-one clearfix">
                                        <div className="area-range-slider"></div>
                                        <div className="input"><input type="text" className="property-amount" id="area" name="area" readOnly/></div>
                                        <div className="title">m<sup>2</sup></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="range-slider-one clearfix">
                                        <div className="price-range-slider"></div>
                                        <div className="input"><input type="text" className="price-amount" id="price" name="price" readOnly/></div>
                                        <div className="title">Euro</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="theme-btn btn-style-one">Search</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                    <div className="sidebar-widget categories">
                    <div className="sidebar-title"><h2>Category Properties</h2></div>
                    <ul className="cat-list">
                    {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'/nieruchomosci-wykaz/kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}                     
                    </ul>
                </div>

                </aside>
            </div>
        </div>
    </div>
</div>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
        </>
    )
}
export const PListSearch=()=>{  
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     const data =useActionData();
  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
         <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>NIERUCHOMOŚCI</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li>NIERUCHOMOŚCI</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <div className="sidebar-page-container">
    <div className="auto-container">
        <div className="row clearfix">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="property-list-view">
                    <div className="upper-box clearfix">
                    <div className="sec-title">
                            <span className="title">ZNAJDŹ SWÓJ WYMARZONY DOM</span>
                            <h2>NIERUCHOMOŚCI</h2>
                        </div>
                    </div>
                    {data.properties?.map((item,i)=>(
                        <div key={item.id} className="property-block-three">
                        <div className="inner-box">
                            <div className="row clearfix">
                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="image-box">
                                    <Link to={'/nieruchomosci/'+item.id}><figure className="image"><img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cPicture} alt={item.title}/></figure></Link> 
                                    <span className="for">{item.price}</span>
                                    <span className="featured">{item.type}</span>
                                        <ul className="info clearfix">
                                            <li><i className="la la-calendar-minus-o"></i>{item.builddate}</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                    <div className="lower-content">
                                        <ul className="tags">
                                            <li><Link to={'/nieruchomosci-wykaz/kategoria/'+item.category}>{item.category}</Link></li>
                                        </ul>
                                        <div className="thumb"><img src={IMG_URL+'mc/60/60/5/agent/'+item.agentp} alt={item.title}/></div>
                                        <h3><Link to={'/nieruchomosci/'+item.id}>{item.title}</Link></h3>
                                        <div className="lucation"><i className="la la-map-marker"></i>{item.address +" "+ item.location}</div>
                                        <ul className="property-info clearfix">
                                        <li className="col-md-6"><i className="flaticon-dimension"></i>{item.area}</li>
                                            <li className="col-md-6"><i className="flaticon-house-silhouette"></i>{item.room}</li>
                                            <li className="col-md-6"><i className="flaticon-can-sun-umbrella-3"></i>{item.dSea}</li>
                                            <li className="col-md-6"><i className="flaticon-can-plane-1"></i>{item.dAirport}</li>                                            
                                        </ul>
                                        <div className="property-price clearfix">
                                            <div className="read-more"><Link to={'/nieruchomosci/'+item.id} className="theme-btn">SZCZEGÓŁY</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        ))}                      
                </div>
            </div>
            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar default-sidebar">
                <div className="sidebar-widget search-properties">
                    <div className="sidebar-title"><h2>ZNAJDŹ NIERUCHOMOŚĆ</h2></div>
                    <div className="property-search-form style-three">
                    <Form method='post' action='/nieruchomosci-wykaz/Szukaj'>
                            <div className="row no-gutters">
                            <div className="form-group">
                                    <input type="text" name="text" placeholder='NAZWA' />
                                </div>
                                <div className="form-group">
                                    <select id="tip" name="tip" className="custom-select-box">
                                    <option value="Any">KUPNO/WYNAJEM</option>
                                    <option value="Na Sprzedaż">Na Sprzedaż</option>
                                        <option value="Do Wynajęcia">Do Wynajęcia</option>
                                        <option value="Sprzedany">Sprzedany</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                <select id="location" name="location" className="custom-select-box">
                                    <option value={"Any"}>LOKALIZACJA</option>
                                    {data.locations?.map((item,i)=>(
                                        <option key={i} value={item}>{item}</option>
                                        ))} 
                                </select>
                                </div>
                                <div className="form-group">
                                <select id="room" name="room" className="custom-select-box">
                                        <option value="Any">ILOŚĆ POKOI</option>
                                        {data.rooms?.map((item,i)=>(
                                            <option key={i} value={item}>{item}</option>
                                            ))} 
                                    </select>
                                </div>
                                <div className="form-group">
                                <div className="range-slider-one clearfix">
                                        <div className="area-range-slider"></div>
                                        <div className="input"><input type="text" className="property-amount" id="area" name="area" readOnly/></div>
                                        <div className="title">m<sup>2</sup></div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="range-slider-one clearfix">
                                        <div className="price-range-slider"></div>
                                        <div className="input"><input type="text" className="price-amount" id="price" name="price" readOnly/></div>
                                        <div className="title">Euro</div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="theme-btn btn-style-one">WYSZUKAJ</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                    <div className="sidebar-widget categories">
                    <div className="sidebar-title"><h2>RODZAJE NIERUCHOMOŚCI</h2></div>
                    <ul className="cat-list">
                    {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'/nieruchomosci-wykaz/kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}                     
                    </ul>
                </div>

                </aside>
            </div>
            
        </div>
    </div>
</div>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
        </>
    )
}
export const pcatLoader = async({params})=>{
    const {cat}=params;
    const response= await createAPIendpoint("getPropertylistbycat")
    .getPropertylistbycat(cat)
    return response.data;
}
export const pLoader = async()=>{
    const response= await createAPIendpoint("Getpropertylist")
    .getPropertylist();
    return response.data;
}
export const psearchAction = async({request})=>{
    const data=await request.formData();
    const Searchdata={
        text:data.get('text'),
        tip:data.get('tip'),
        location:data.get('location'),
        room:data.get('room'),
        area:data.get('area'),
        price:data.get('price')

    }
    const response= await createAPIendpoint("Getpropertysearch")
    .getPropertylistbysearch(Searchdata);
    return response.data;
}