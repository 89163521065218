import { IMG_URL } from "../api/parameters"
import { Link} from 'react-router-dom';

export const Owl=(pdata)=>{   
    return(  
 <div className="property-carousel owl-carousel owl-theme">
    {pdata.pdata?.map((item)=>(
    <div key={item.id} className="new-property">
    <div className="row">
       <div className="content-column col-lg-6 col-md-12 col-sm-12">
          <div className="inner-column">
             <span className="status">{item.type}</span>
             <h3><Link to={'/nieruchomosci/'+item.id}>{item.title}</Link></h3>
             <div className="start-from"> {item.price}</div>
             <div className="lucation"><i className="la la-map-marker"></i>{item.address +" "+ item.location}</div>

             <div className="text">{item.shortdesc}</div>

             <ul className="property-info clearfix">
                 <li className="col-md-3"><i className="flaticon-small-calendar"></i> {item.builddate}</li>
                 <li className="col-md-3"><i className="flaticon-dimension"></i>{item.area}</li>
                 <li className="col-md-3"><i className="flaticon-house-silhouette"></i>{item.room}</li>
                 <li className="col-md-3"><i className="flaticon-maps-and-flags"></i>{item.location}</li>
                 <li className="col-md-3"><i className="flaticon-can-sun-umbrella-3"></i>{item.dSea}</li>
                 <li className="col-md-3"><i className="flaticon-can-plane-1"></i>{item.dAirport}</li>
            </ul>
      </div>
  </div>

  <div className="image-column col-lg-6 col-md-12 col-sm-12">
      <div className="inner-column">
         <figure className="image"><img src={IMG_URL+'mc/467/440/5/property/showcase/'+item.scPicture} alt={item.title}/></figure>
     </div>
 </div>
</div>
</div>
    ))}
 

</div> 
     
    )
}
export const Property=(pdata)=>{   
    return(  
<>
{pdata.pdata?.map((item)=>(
 <div key={item.id} className={"property-block all mix "+ item.type.replace(" ","-")+" col-lg-4 col-md-6 col-sm-12"}>
 <div className="inner-box">
     <div className="image-box">
     <Link to={'/nieruchomosci/'+item.id}><figure className="image"><img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cPicture} alt={item.title}/></figure></Link>
         <span className="for">{item.type}</span>
         <span className="featured">{item.price}</span>
         <ul className="info clearfix">
             <li><i className="la la-calendar-minus-o"></i>{item.builddate}</li>
         </ul>
     </div>
     <div className="lower-content">
         <ul className="tags">
             <li><Link to={'/nieruchomosci-wykaz/kategoria/'+item.category}>{item.category}</Link></li>
         </ul>
         <div className="thumb"><img src={IMG_URL+'mc/60/60/5/agent/'+item.agentp} alt={item.title}/></div>
         <h3><Link to={'/nieruchomosci/'+item.id}>{item.title}</Link></h3>
         <div className="lucation"><i className="la la-map-marker"></i>{item.address +" "+ item.location}</div>
         <ul className="property-info clearfix">
                                            <li className="col-md-6"><i className="flaticon-small-calendar"></i>{item.builddate}</li>
                                            <li className="col-md-6"><i className="flaticon-dimension"></i>{item.area}</li>
                                            <li className="col-md-6"><i className="flaticon-house-silhouette"></i>{item.room}</li>
                                            <li className="col-md-6"><i className="flaticon-maps-and-flags"></i>{item.location}</li>
                                            <li className="col-md-6"><i className="flaticon-can-sun-umbrella-3"></i>{item.dSea}</li>
                                            <li className="col-md-6"><i className="flaticon-can-plane-1"></i>{item.dAirport}</li>
                                        </ul>
     </div>
 </div>
</div>
    ))}              
</>
     
    )
}
export const City=(pdata)=>{   
    return(  
<>
{pdata.pdata  &&
<div className="masonry-items-container clearfix">
            <div className="popular-item masonry-item medium-item">
                <div className="image-box">
                    <figure className="image"><img src={IMG_URL+'Img/Cities/Cover/1/'+pdata.pdata[0].cover} alt={pdata.pdata[0].name}/></figure>
                    <div className="info-box">
                        <h3 className="place"><Link to={'/co-zwiedzic-w-turcji/'+pdata.pdata[0].id}>{pdata.pdata[0].name}</Link></h3>
                        <div className="properties"><Link to={'/nieruchomosci-wykaz/Miasto/'+pdata.pdata[0].name}>{pdata.pdata[0].properties+' Properties'}</Link></div>
                        <div className="view-all"><Link to="/co-zwiedzic-w-turcji">View All</Link></div>
                    </div>
                </div>
            </div>
            <div className="popular-item masonry-item medium-item">
                <div className="image-box">
                    <figure className="image"><img src={IMG_URL+'Img/Cities/Cover/2/'+pdata.pdata[1].cover} alt={pdata.pdata[1].name}/></figure>
                    <div className="info-box">
                        <h3 className="place"><Link to={'/co-zwiedzic-w-turcji/'+pdata.pdata[1].id}>{pdata.pdata[1].name}</Link></h3>
                        <div className="properties"><Link to={'/nieruchomosci-wykaz/Miasto/'+pdata.pdata[1].name}>{pdata.pdata[1].properties+' Properties'}</Link></div>
                        <div className="view-all"><Link to="/co-zwiedzic-w-turcji">View All</Link></div>
                    </div>
                </div>
            </div>
            <div className="popular-item masonry-item small-item">
                <div className="image-box">
                    <figure className="image"><img src={IMG_URL+'Img/Cities/Cover/3/'+pdata.pdata[2].cover} alt={pdata.pdata[2].name}/></figure>
                    <div className="info-box">
                        <h3 className="place"><Link to={'/co-zwiedzic-w-turcji/'+pdata.pdata[2].id}>{pdata.pdata[2].name}</Link></h3>
                        <div className="properties"><Link to={'/nieruchomosci-wykaz/Miasto/'+pdata.pdata[2].name}>{pdata.pdata[2].properties+' Properties'}</Link></div>
                        <div className="view-all"><Link to="/co-zwiedzic-w-turcji">View All</Link></div>
                    </div>
                </div>
            </div>
            <div className="popular-item masonry-item small-item">
                <div className="image-box">
                    <figure className="image"><img src={IMG_URL+'Img/Cities/Cover/3/'+pdata.pdata[3].cover} alt={pdata.pdata[3].name}/></figure>
                    <div className="info-box">
                        <h3 className="place"><Link to={'/co-zwiedzic-w-turcji/'+pdata.pdata[3].id}>{pdata.pdata[3].name}</Link></h3>
                        <div className="properties"><Link to={'/nieruchomosci-wykaz/Miasto/'+pdata.pdata[3].name}>{pdata.pdata[3].properties+' Properties'}</Link></div>
                        <div className="view-all"><Link to="/co-zwiedzic-w-turcji">View All</Link></div>
                    </div>
                </div>
            </div>
        </div> 
}          
</>
     
    )
}
export const Blog=(bdata)=>{
    return(
        <>
        {bdata.bdata &&
             <section className="news-section">
             <div className="auto-container">
                 <div className="sec-title">
                     <span className="title">BLOG</span>
                     <h2>CIEKAWOSTKI I INFORMACJE PRAKTYCZNE O NIERUCHOMOŚCIACH I ŻYCIU W TURCJI</h2>
                 </div>
                 
                 <div className="row">
                 {bdata.bdata?.map((item)=>(
  <div key={item.id} className="news-block col-lg-4 col-md-6 col-sm-12">
  <div className="inner-box">
      <div className="image-box">
          <figure className="image"><Link to={'/blog/'+item.href}><img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/></Link></figure>
      </div>
      <div className="lower-content">
       <ul className="info">
          <li><i className="la la-user"></i> By <span>{item.by}</span></li>
          <li><i className="la la-calendar"></i> {item.date}</li>
      </ul>
      <h3><Link to={'/blog/'+item.href}>{item.name}</Link></h3>

  </div>
</div>
</div>
                 ))} 
                 
         </div>
         <div className="load-more-btn text-center">
                <Link to="/blog" className="theme-btn btn-style-one">ZOBACZ WIĘCEJ</Link>
            </div>
         </div>
         </section>
        }
        </>
        
   
    )
}
export const Guideblog=(bdata)=>{
    return(
        <>
        {bdata.bdata &&
             <section className="news-section">
             <div className="auto-container">
                 <div className="sec-title">
                     <span className="title">PRZEWODNIK KUPUJĄCEGO</span>
                     <h2>INFORMACJE DOTYCZĄCE ZAKUPU NIERUCHOMOŚCI W TURCJI</h2>
                 </div>
                 
                 <div className="row">
                 {bdata.bdata?.map((item)=>(
  <div key={item.id} className="news-block col-lg-4 col-md-6 col-sm-12">
  <div className="inner-box">
      <div className="image-box">
          <figure className="image"><Link to={'/przewodnik/'+item.href}><img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/></Link></figure>
      </div>
      <div className="lower-content">
       <ul className="info">
          <li><i className="la la-user"></i> By <span>{item.by}</span></li>
          <li><i className="la la-calendar"></i> {item.date}</li>
      </ul>
      <h3><Link to={'/przewodnik/'+item.href}>{item.name}</Link></h3>

  </div>
</div>
</div>
                 ))} 
                 
         </div>
         <div className="load-more-btn text-center">
                <Link to="/przewodnik" className="theme-btn btn-style-one">ZOBACZ WIĘCEJ</Link>
            </div>
         </div>
         </section>
        }
        </>
        
   
    )
}

