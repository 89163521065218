import {
  createBrowserRouter, 
  createRoutesFromElements,
  Route, 
  RouterProvider
} from 'react-router-dom';
import { AboutUs, aboutusLoader } from './views/aboutus';
import { Agent, agentDetailLoader } from './views/agent';
import { Blogdetail, blogDetailLoader, Bloglist, Bloglistbycat, blogListCatLoader, blogListLoader, Guidedetail, guideDetailLoader, Guidelist, guideListLoader } from './views/blog';
import { Contact, contactLoader } from './views/contact';
import {Home, mainpageLoader} from './views/home';
import { PDetail, propertyDetailLoader } from './views/propertydetail';
import { pcatLoader, PList, PListCat,PListSearch, pLoader, psearchAction, pSearchLoader } from './views/propertylist';
import { Transfer, transferLoader } from './views/transfer';
import { Insurance, insuranceLoader } from './views/insurance';
import { Mulk, mulkLoader } from './views/mulk';
import { gayriLoader, Gayrimenkul } from './views/gayrimenkul';
const router=createBrowserRouter(
  createRoutesFromElements(
    
    <Route>
    <Route index element={<Home/>} loader={mainpageLoader} />
    <Route path='/nieruchomosci/:id' element={<PDetail/>} loader={propertyDetailLoader}/>
    <Route path='nieruchomosci-wykaz' element={<PList/>} loader={pLoader}/>
    <Route path='/nieruchomosci-wykaz/kategoria/'> 
    <Route path=':cat' element={<PListCat/>} loader={pcatLoader}/>  
    </Route>    
    <Route path='/przedstawiciel/:id' element={<Agent/>} loader={agentDetailLoader}/>
    <Route path='/blog/:href' element={<Blogdetail/>} loader={blogDetailLoader}/>
    <Route path='/blog' element={<Bloglist/>} loader={blogListLoader}/>
    <Route path='/blog/kategoria/:cat' element={<Bloglistbycat/>} loader={blogListCatLoader}/>
    <Route path='/kontakt' element={<Contact/>} loader={contactLoader}/>
    <Route path='/o-nas' element={<AboutUs/>} loader={aboutusLoader}/>
    <Route path='/transfery' element={<Transfer/>} loader={transferLoader}/>
    <Route path='/Ubezpieczenia' element={<Insurance/>} loader={insuranceLoader}/>
    <Route path='/Zarzadzanie-Nieruhomoscia' element={<Mulk/>} loader={mulkLoader}/>
    <Route path='/Wycena-i-Sprzedaz-Nieruchomosci' element={<Gayrimenkul/>} loader={gayriLoader}/>
    <Route path='/przewodnik' element={<Guidelist/>} loader={guideListLoader}/>
    <Route path='/przewodnik/:href' element={<Guidedetail/>} loader={guideDetailLoader}/>
    <Route path='/nieruchomosci-wykaz/Szukaj' element={<PListSearch/>} action={psearchAction}/>
    </Route>
  )
)
function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
