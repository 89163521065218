import { Link, useLoaderData, useParams } from "react-router-dom";
import React,{useState,useEffect} from 'react';
import { createAPIendpoint } from '../api/api';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IMG_URL } from "../api/parameters"
import { Footer, Header} from './shared';
import parse from 'html-react-parser';
const useScript = (src,condition) => {
    useEffect(() => {
            const tag = document.createElement('script');
            tag.async = condition;
            tag.src = src;
            document.body.appendChild(tag);            
            return () => {
              document.body.removeChild(tag);
            }      
    }, [src])  
  }
export const Blogdetail=()=>{
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     const data =useLoaderData();

  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/blogs/sliders/'+data.blog?.slider+')',
}; 
    return(
        <>
         <HelmetProvider>
            <Helmet>
            <title>{data.blog?.name}</title>
        <meta name="description" content={data.blog?.seod}/>
        <meta name="keywords" content={data.blog?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.blog  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
          <h1>BLOG</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li><Link to="/blog">BLOG</Link></li>
                      <li>{data.blog?.name}</li>
                  </ul>
          </div>
      </section>
        }
        <div className="sidebar-page-container left-sidebar">
    <div className="auto-container">
        <div className="row">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
                <div className="blog-detail">
                    <div className="upper-box">
                        <div className="image-box">
                            <figure className="image"><img src={IMG_URL+'mc/1920/1280/5/blogs/body/'+data.blog?.bodyp} alt={data.blog?.name}/></figure>
                        </div>
                    </div>

                    <div className="lower-content">
                        <ul className="info">
                            <li>by <span>{data.blog?.cBy}</span></li>
                            <li>{data.blog?.dateTime.split('-')[2].split('T')[0]+'.'+data.blog?.dateTime.split('-')[1]+'.'+data.blog?.dateTime.split('-')[0]}</li>
                        </ul>
                        {parse(data.blog?.body||'')}

                    </div>

                    <div className="post-share-options clearfix">
                        <div className="pull-left clearfix">
                            <span className="icon la la-tags"></span>
                            <ul className="tags">
                                <li><Link to={'/blog/Kategoria/'+data.blog?.cat}>{data.blog?.cat}</Link></li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
                <aside className="sidebar default-sidebar">

                    

                    <div className="sidebar-widget categories">
                        <div className="sidebar-title"><h2>BLOG KATEGORIE</h2></div>
                        <ul className="cat-list">
                        {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'/blog/kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}                               
                        </ul>
                    </div>
                    {data.blogs?.count>0 &&
                     <div className="sidebar-widget recent-properties">
                     <div className="sidebar-title"><h2>Recent Posts</h2></div>
                     <div className="widget-content">
                     {data.blogs?.map((item,i)=>(
                     <article key={item.id} className="post">
                     <div className="post-thumb">
                         <Link to={'/blog/'+item.href}>
                             <img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/>
                         </Link>
                     </div>
                     <h3><Link to={'/blog/'+item.href}>{item.name}</Link></h3>
                     <div className="price">{item.date}</div>
                 </article>
                     ))}                                 

                        
                     </div>
                 </div>   
                    }
                            
                </aside>
            </div>
        </div>
    </div>
</div>
{data.blog  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1280/5/blogs/footers/'+data.blog?.footer+')'}/>
}
        </div>
        </>
        )
}
export const Bloglist=()=>{
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     const data =useLoaderData();

  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
                  <h1>BLOG</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li>BLOG</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <div className="sidebar-page-container">
  <div className="auto-container">
    <div className="row clearfix">
      <div className="content-side col-lg-8 col-md-12 col-sm-12">
        <div className="blog-grid-view">
          <div className="row">
          {data.blogs?.map((item,i)=>(
            <div key={item.id} className="news-block col-lg-6 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image"><Link to={'/blog/'+item.href}><img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/></Link></figure>
                </div>
                <div className="lower-content">
                  <ul className="info">
                   <li><i className="la la-user"></i> By <span>{item.by}</span></li>
                   <li><i className="la la-calendar"></i>{item.date}</li>
                 </ul>
                 <h3><Link to={'/blog/'+item.href}>{item.name}</Link></h3>
               </div>
             </div>
           </div>
          ))}           

          
</div>
</div>
</div>
<div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
  <aside className="sidebar default-sidebar">
    <div className="sidebar-widget categories">
      <div className="sidebar-title"><h2>KATEGORIE</h2></div>
      <ul className="cat-list">
      {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'/blog/kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}   
      </ul>
    </div>
    <div className="sidebar-widget recent-properties">
      <div className="sidebar-title"><h2>NAJNOWSZE OFERTY</h2></div>
      <div className="widget-content">
      {data.properties?.map((item,i)=>(
                          <article key={item.id} className="post">
                          <div className="post-thumb">
                              <Link to={'/nieruchomosci/'+item.id}>
                                  <img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cover} alt={item.name}/>
                              </Link>
                          </div>
                          <h3><Link to={'/nieruchomosci/'+item.id}>{item.name}</Link></h3>
                          <div className="price">{item.date}</div>
                      </article>
                        ))}    
      </div>
    </div>


  </aside>
</div>
</div>
</div>
</div>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
        </>
    )
}
export const Bloglistbycat=()=>{
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
     const data =useLoaderData();

  const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
    return(
        <>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.seod}/>
        <meta name="keywords" content={data.pages?.seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>
        <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
        <div className="page-wrapper">
        <Header/>
        {data.pages  &&
          <section className="page-title" style={sliderImage}>
          <div className="auto-container">
              <div className="inner-container clearfix">
              <h1>BLOG</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/">STRONA GŁÓWNA</Link></li>
                      <li>BLOG</li>
                  </ul>
              </div>
          </div>
      </section>
        }
        <div className="sidebar-page-container">
  <div className="auto-container">
    <div className="row clearfix">
      <div className="content-side col-lg-8 col-md-12 col-sm-12">
        <div className="blog-grid-view">
          <div className="row">
          {data.blogs?.map((item,i)=>(
            <div key={item.id} className="news-block col-lg-6 col-md-6 col-sm-12">
              <div className="inner-box">
                <div className="image-box">
                  <figure className="image"><Link to={'/blog/'+item.href}><img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/></Link></figure>
                </div>
                <div className="lower-content">
                  <ul className="info">
                   <li><i className="la la-user"></i> By <span>{item.by}</span></li>
                   <li><i className="la la-calendar"></i>{item.date}</li>
                 </ul>
                 <h3><Link to={'/blog/'+item.href}>{item.name}</Link></h3>
               </div>
             </div>
           </div>
          ))}           

          
</div>
</div>
</div>
<div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
  <aside className="sidebar default-sidebar">
    <div className="sidebar-widget categories">
      <div className="sidebar-title"><h2>Categories</h2></div>
      <ul className="cat-list">
      {data.categories?.map((item,i)=>(
                        <li key={i}><Link to={'/blog/kategoria/'+item.name}>{item.name} <span>{item.number}</span></Link></li>
                        ))}   
      </ul>
    </div>
    <div className="sidebar-widget recent-properties">
      <div className="sidebar-title"><h2>Recent Posts</h2></div>
      <div className="widget-content">
      {data.properties?.map((item,i)=>(
                          <article className="post">
                          <div className="post-thumb">
                              <Link to={'/nieruchomosci/'+item.id}>
                                  <img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cover} alt={item.name}/>
                              </Link>
                          </div>
                          <h3><Link to={'/nieruchomosci/'+item.id}>{item.name}</Link></h3>
                          <div className="price">{item.date}</div>
                      </article>
                        ))}    
      </div>
    </div>


  </aside>
</div>
</div>
</div>
</div>
{data.pages  &&
    <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
        </div>
        </>
    )
}
export const Guidelist=()=>{
  const data =useLoaderData();
  useScript('./assets/js/jquery.js',false);
   useScript('./assets/js/bootstrap.min.js',false);
   useScript('./assets/js/popper.min.js',false);      
   useScript('./assets/js/jquery-ui.js',false);
   useScript('./assets/js/jquery.fancybox.js',false);
   useScript('./assets/js/owl.js',false);
   useScript('./assets/js/wow.js',false);
   useScript('./assets/js/isotope.js',false);
   useScript('./assets/js/mixitup.js',false);
   useScript('./assets/js/appear.js',false);
   useScript('./assets/js/validate.js',false);
   useScript('./assets/js/script.js',false);
   useScript('./assets/js/scroll.js',false);

const sliderImage = {
  backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/Sliders/'+data.pages?.slider+')'
}; 
  return(
      <>
      <HelmetProvider>
          <Helmet>
          <title>{data.pages?.title}</title>
      <meta name="description" content={data.pages?.seod}/>
      <meta name="keywords" content={data.pages?.seok}/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
          </Helmet>
      </HelmetProvider>
      <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
      <div className="page-wrapper">
      <Header/>
      {data.pages  &&
        <section className="page-title" style={sliderImage}>
        <div className="auto-container">
            <div className="inner-container clearfix">
                <h1>PRZEWODNIK KUPUJĄCEGO</h1>
                <ul className="bread-crumb clearfix">
                    <li><Link to="/">STRONA GŁÓWNA</Link></li>
                    <li>PRZEWODNIK KUPUJĄCEGO</li>
                </ul>
            </div>
        </div>
    </section>
      }
      <div className="sidebar-page-container">
<div className="auto-container">
  <div className="row clearfix">
    <div className="content-side col-lg-8 col-md-12 col-sm-12">
      <div className="blog-grid-view">
        <div className="row">
        {data.guides?.map((item,i)=>(
          <div key={item.id} className="news-block col-lg-6 col-md-6 col-sm-12">
            <div className="inner-box">
              <div className="image-box">
                <figure className="image"><Link to={item.href}><img src={IMG_URL+'mc/950/620/5/blogs/cover/'+item.cover} alt={item.name}/></Link></figure>
              </div>
              <div className="lower-content">
                <ul className="info">
                 <li><i className="la la-user"></i> By <span>{item.by}</span></li>
                 <li><i className="la la-calendar"></i>{item.date}</li>
               </ul>
               <h3><Link to={'/przewodnik/'+item.href}>{item.name}</Link></h3>
             </div>
           </div>
         </div>
        ))}           

        
</div>
</div>
</div>
<div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
<aside className="sidebar default-sidebar">
  <div className="sidebar-widget recent-properties">
    <div className="sidebar-title"><h2>NAJNOWSZE OFERTY</h2></div>
    <div className="widget-content">
    {data.properties?.map((item,i)=>(
                        <article key={item.id} className="post">
                        <div className="post-thumb">
                            <Link to={'/nieruchomosci/'+item.id}>
                                <img src={IMG_URL+'mc/370/320/5/property/cover/'+item.cover} alt={item.name}/>
                            </Link>
                        </div>
                        <h3><Link to={'/nieruchomosci/'+item.id}>{item.name}</Link></h3>
                        <div className="price">{item.date}</div>
                    </article>
                      ))}    
    </div>
  </div>


</aside>
</div>
</div>
</div>
</div>
{data.pages  &&
  <Footer footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}
      </div>
      </>
  )
}
export const Guidedetail=()=>{  
  const data =useLoaderData();
  useScript('./assets/js/jquery.js',false);
   useScript('./assets/js/bootstrap.min.js',false);
   useScript('./assets/js/popper.min.js',false);      
   useScript('./assets/js/jquery-ui.js',false);
   useScript('./assets/js/jquery.fancybox.js',false);
   useScript('./assets/js/owl.js',false);
   useScript('./assets/js/wow.js',false);
   useScript('./assets/js/isotope.js',false);
   useScript('./assets/js/mixitup.js',false);
   useScript('./assets/js/appear.js',false);
   useScript('./assets/js/validate.js',false);
   useScript('./assets/js/script.js',false);
   useScript('./assets/js/scroll.js',false);

const sliderImage = {
  backgroundImage: 'url('+IMG_URL+'mc/1919/400/5/blogs/sliders/'+data?.slider+')',
}; 
  return(
      <>
       <HelmetProvider>
          <Helmet>
          <title>{data?.name}</title>
      <meta name="description" content={data?.seod}/>
      <meta name="keywords" content={data?.seok}/>
      <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
          </Helmet>
      </HelmetProvider>
      <button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>
      <div className="page-wrapper">
      <Header/>
      {data  &&
        <section className="page-title" style={sliderImage}>
        <div className="auto-container">
            <div className="inner-container clearfix">
                <h1>PRZEWODNIK KUPUJĄCEGO</h1>
                <ul className="bread-crumb clearfix">
                    <li><Link to="/">STRONA GŁÓWNA</Link></li>
                    <li><Link to="/nieruchomosci-wykaz">PRZEWODNIK KUPUJĄCEGO</Link></li>
                    <li>{data?.name}</li>
                </ul>
            </div>
        </div>
    </section>
      }
      <section className="testimonial-page-section">
 <div className="auto-container">
  {parse(data?.body||'')}

</div>
</section>
{data  &&
  <Footer footerImg={'url('+IMG_URL+'mc/1920/1280/5/blogs/footers/'+data?.footer+')'}/>
}
      </div>
      </>
      )
}
export const guideDetailLoader = async({params})=>{
  const {href}=params;
  const response= await  createAPIendpoint("Getguide")
  .Getguide(href);
  return response.data;
}
export const blogListCatLoader = async({params})=>{
  const {cat}=params;
  const response= await createAPIendpoint("Getbloglistbycat")
  .Getbloglistbycat(cat)
  return response.data;
}
export const guideListLoader = async()=>{
  const response= await  createAPIendpoint("Getguidelist")
  .Getguidelist();
  return response.data;
}
export const blogListLoader = async()=>{
  const response= await createAPIendpoint("Getbloglist")
  .Getbloglist()
  return response.data;
}
export const blogDetailLoader = async({params})=>{
  const {href}=params;
  const response= await createAPIendpoint("Getblog")
  .Getblog(href)
  return response.data;
}