//import '../src-assets/css/bootstrap.css';
//import '../src-assets/css/style.css';
//import '../src-assets/css/responsive.css';
import { Footer, Header } from './shared';
import React,{useEffect} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createAPIendpoint } from '../api/api';
import { IMG_URL } from "../api/parameters";
import { Blog, Guideblog, Owl, Property } from '../controllers/mainpage';
import { Link,useLoaderData, Form  } from "react-router-dom";
const useScript = (src,condition) => {
    useEffect(() => {
            const tag = document.createElement('script');
            tag.async = condition;
            tag.src = src;
            document.body.appendChild(tag);            
            return () => {
              document.body.removeChild(tag);
            }      
    }, [src])  
  }
export const Home=()=>{
    const data =useLoaderData();
    useScript('./assets/js/jquery.js',false);
     useScript('./assets/js/bootstrap.min.js',false);
     useScript('./assets/js/popper.min.js',false);      
     useScript('./assets/js/jquery-ui.js',false);
     useScript('./assets/js/jquery.fancybox.js',false);
     useScript('./assets/js/owl.js',false);
     useScript('./assets/js/wow.js',false);
     useScript('./assets/js/isotope.js',false);
     useScript('./assets/js/mixitup.js',false);
     useScript('./assets/js/appear.js',false);
     useScript('./assets/js/validate.js',false);
     useScript('./assets/js/script.js',false);
     useScript('./assets/js/scroll.js',false);
      const sliderImage = {
    backgroundImage: 'url('+IMG_URL+'mc/1920/900/5/Sliders/'+data.pages?.slider+')'
    }  
    return (
       <div>
        <HelmetProvider>
            <Helmet>
            <title>{data.pages?.title}</title>
        <meta name="description" content={data.pages?.Seod}/>
        <meta name="keywords" content={data.pages?.Seok}/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"></meta>
            </Helmet>
        </HelmetProvider>

<button id="back-to-top-btn"><i className="la la-angle-double-up"></i></button>

<div className="page-wrapper">
<Header/>
{data.pages  &&
    <section className="property-search-section-two" style={sliderImage}>
  <div className="auto-container">
     <div className="content-box">
        <div className="title-box">           
           <h2>Zamieszkaj w <span>ciepłym</span> kraju.</h2>
           <h4>Od dawna marzyłeś by zamieszkać w ciepłym kraju? Turcja to świetny wybór. Tanio, słonecznie i blisko. Pomożemy spełnić Ci te marzenia i wybrać wymarzone mieszkanie w Turcji dla Twojej rodziny.</h4>
       </div>

   </div>
</div>
</section>
}

<section className="property-search-section">
    <div className="auto-container">
        <div className="property-search-tabs tabs-box">

            <div className="tabs-content">
                <div className="tab active-tab" id="sale">                         
                    <div className="property-search-form">
                        <Form method='post' action='/nieruchomosci-wykaz/Szukaj'>
                            <div className="row">                               
                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                    <label>KUPNO/WYNAJEM</label>
                                    <select id="tip" name="tip" className="custom-select-box">
                                        <option value="Any">Wszystkie</option>
                                        <option value="Na Sprzedaż">Na Sprzedaż</option>
                                        <option value="Do Wynajęcia">Do Wynajęcia</option>
                                        <option value="Sprzedany">Sprzedany</option>
                                    </select>
                                </div>
                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                    <label>LOKALIZACJA</label>
                                    <select id="location" name="location" className="custom-select-box">
                                        <option value={"Any"}>Wszystkie</option>
                                        {data.locations?.map((item,i)=>(
                                            <option key={i} value={item}>{item}</option>
                                            ))} 
                                    </select>
                                </div>
                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                    <label>ILOŚĆ POKOI</label>
                                    <select id="room" name="room" className="custom-select-box">
                                        <option value="Any">Wszystkie</option>
                                        {data.rooms?.map((item,i)=>(
                                            <option key={i} value={item}>{item}</option>
                                            ))} 
                                    </select>
                                </div>
                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                    <label>nazwa</label>
                                    <input type="text" name="text" />
                                </div>
                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                    <div className="range-slider-one clearfix">
                                        <label>POWIERZCHNIA</label>
                                        <div className="area-range-slider"></div>
                                        <div className="input"><input type="text" className="property-amount" id="area" name="area" readOnly/></div>
                                        <div className="title">m<sup>2</sup></div>
                                    </div>
                                </div>

                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                    <div className="range-slider-one clearfix">
                                        <label>CENA</label>
                                        <div className="price-range-slider"></div>
                                        <div className="input"><input type="text" className="price-amount" id="price" name="price" readOnly/></div>
                                        <div className="title">Euro</div>
                                    </div>
                                </div>
                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                </div> <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                </div>                                                       
                                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                                    <button type="submit" className="theme-btn btn-style-two">WYSZUKAJ</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="property-section-two">
   <div className="auto-container">
      <div className="sec-title">
         <span className="title">ZNAJDŹ SWÓJ WYMARZONY DOM</span>
         <h2>SZCZEGÓLNIE POLECAMY</h2>
     </div>

   <Owl pdata={data?.scProperties}/>
</div>
</section>
<section className="property-filter-section">
    <div className="auto-container">
        <div className="mixitup-gallery">
            <div className="upper-box clearfix">
                <div className="sec-title">
                    <span className="title">ZNAJDŹ SWÓJ WYMARZONY DOM</span>
                    <h2>NAJCIEKAWSZE OFERY</h2>
                </div>

                <div className="filters">
                    <ul className="filter-tabs filter-btns clearfix">
                        <li className="active filter" data-role="button" data-filter="all">Wszystkie</li>
                        <li className="filter" data-role="button" data-filter=".Na-Sprzedaż">Na Sprzedaż</li>
                        <li className="filter" data-role="button" data-filter=".Do-Wynajęcia">Do Wynajęcia</li>
                    </ul>                                    
                </div>
            </div>

            <div className="filter-list row">
            
            <Property pdata={data?.mpProperties}/>

            </div>

            <div className="load-more-btn text-center">
                <Link to="/nieruchomosci-wykaz" className="theme-btn btn-style-two">ZOBACZ WIĘCEJ</Link>
            </div>
        </div>
    </div>
</section>
<section className="fun-facts-section">
    <div className="auto-container">

        <div className="fun-facts">
        <div className="sec-title light">
            <h2>JAK PRACUJEMY</h2>
        </div>
           <div className="row clearfix">
               <div className="column count-box col-lg-3 col-md-6 col-sm-12">
                <div className="content">
                    <div className="icon-box"><span className="la la-language"></span></div>
                    <div className="count-outer count-box">
                        
                    </div>
                    <div className="counter-title">Kontakt zawsze w języku polskim</div>
                </div>
            </div>

            <div className="column count-box col-lg-3 col-md-6 col-sm-12">
                <div className="content">
                    <div className="icon-box"><span className="la la-user-secret"></span></div>
                    <div className="count-outer count-box">
                    </div>
                    <div className="counter-title">Tylko sprawdzone oferty</div>
                </div>
            </div>

            <div className="column count-box col-lg-3 col-md-6 col-sm-12">
                <div className="content">
                    <div className="icon-box"><span className="la la-book"></span></div>
                    <div className="count-outer count-box">
                    </div>
                    <div className="counter-title">Posiadamy wszytskie dokumenty wymagane dla agencji nieruchomości – działamy legalnie </div>
                </div>
            </div>
            <div className="column count-box col-lg-3 col-md-6 col-sm-12">
                <div className="content">
                    <div className="icon-box"><span className="la la-life-bouy"></span></div>
                    <div className="count-outer count-box">
                    </div>
                    <div className="counter-title">Opieka nad klientem po sfinalizowaniu zakupu</div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<Guideblog bdata={data?.guide}/>

<section className="process-section" style={{backgroundImage: 'url(assets/images/background/home_slider_01.jpg)'}}>
    <div className="auto-container">
        <div className="sec-title light">
            <h2>USŁUGI DODATKOWE</h2>
        </div>

        <div className="row">
            <div className="process-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-box"><span className="la la-bus"></span></div>
                    <h4><Link to="/Transfery">Transfery</Link></h4>
                </div>
            </div>
            <div className="process-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-box"><span className="la la-life-bouy"></span></div>
                    <h4><Link to="/Ubezpieczenia">Ubezpieczenia</Link></h4>
                </div>
            </div>
            <div className="process-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-box"><span className="la la-home"></span></div>
                    <h4><Link to="/Zarzadzanie-Nieruhomoscia">Zarządzanie Nieruchomością</Link></h4>
                </div>
            </div>
            <div className="process-block col-lg-3 col-md-6 col-sm-12">
                <div className="inner-box">
                    <div className="icon-box"><span className="la la-calculator"></span></div>
                    <h4><Link to="/Wycena-i-Sprzedaz-Nieruchomosci">Wycena i Sprzedaż Nieruchomości</Link></h4>
                </div>
            </div>
        </div>
    </div>
</section>    
<Blog bdata={data?.blogs}/>
<section className="clients-section style-two">
    <div className="auto-container">
        <div className="sponsors-outer">
            <ul className="sponsors-carousel owl-carousel owl-theme">
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://metintour.pl"><img src="assets/images/clients/metintour.png" alt="Metin Tour"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://nonstoptransfer.com"><img src="assets/images/clients/nonstoptransfer.png" alt="Non Stop Transfer"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://turkusturizm.com.tr"><img src="assets/images/clients/Turkustourism.jpg" alt="Turkus Tourism"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://domwturcji.pl"><img src="assets/images/clients/Domwturcjiorj.png" alt="Dom w Turcji"/></a></figure></li>
                <li className="slide-item"><figure className="image-box"><a target='_blank' href="https://alanyamotor.com"><img src="assets/images/clients/metam-logo.png" alt="Honda Alanya Motor"/></a></figure></li>
            </ul>
        </div>
    </div>
</section>
{data.pages  &&
    <Footer  footerImg={'url('+IMG_URL+'mc/1920/1080/5/footers/'+data.pages?.footer+')'}/>
}

</div>
       </div>
    )
}
export const mainpageLoader = async()=>{
    const response= await createAPIendpoint("Pages")
    .getMainpage(0)
    return response.data;
}